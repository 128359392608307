<template>
    <div class="shop_main" v-loading="isRequestLoading">
        <div class="shop_search_menu">
            <a href="javascript:void(0)" @click="$router.push('/index')">首页</a>
            <i class="el-icon-arrow-right"></i>
            <a href="javascript:void(0)" @click="goList(category1Id)">{{category1Value}}</a>
            <i class="el-icon-arrow-right"></i>
            <a href="javascript:void(0)" @click="goList(category2Id)">{{category2Value}}</a>
            <i class="el-icon-arrow-right"></i>
            <a href="javascript:void(0)" @click="goList(category3Id)">{{category3Value}}</a>
        </div>

        <div class="shop_detail">
            <div class="goods_info">
                <div class="banner">
                    <el-image :src="image[imageLarge] ? imageURL + image[imageLarge] : ''" :preview-src-list="[imageURL + image[imageLarge]]" class="large" fit="contain"></el-image>

                    <div class="images">
                        <div class="btn"><img src="../../../assets/img/shop_detail_left.png" @click="imageLargePre" /></div>
                        <div class="imgInfo">
                            <div class="img" :style="{width: (62 * 6 - 8) + 'px', transform: 'translateX(' + imageSlateX + 'px) scale(1)'}">
                                <el-image v-for="(item, index) in image" :key="index" :src="imageURL + item" :class="index === imageLarge ? 'on' : ''" fit="contain" @click="imageLarge = index"></el-image>
                            </div>
                        </div>
                        <div class="btn"><img src="../../../assets/img/shop_detail_right.png" @click="imageLargePlus" /></div>
                    </div>
                </div>

                <div class="info">
                    <div class="contain">
                        <div class="left">
                            <div class="detail">
                                <h2 class="name">{{name}}</h2>

                                <div class="infoDet">
                                    <div class="infoDetLabel">
                                        <div class="item">
                                            <div class="text">商品价格：</div>
                                        </div>

<!--                                        <div class="item">-->
<!--                                            <div class="text">成交方式：</div>-->
<!--                                        </div>-->

                                        <div class="item" v-for="(list, index) in skuType" :key="index">
                                            <div class="text">{{list.skukey}}</div>：
                                        </div>

                                        <div class="item">
                                            <div class="text">商品库存</div>：
                                        </div>

                                        <div class="item">
                                            <div class="text">商品数量</div>：
                                        </div>
                                    </div>

                                    <div class="infoDetValue">
                                        <div class="item">
                                            <span class="price">¥ {{price}}</span>
                                        </div>

<!--                                        <div class="item">-->
<!--                                            <span class="text small">全额付款｜预付款（预付款占比30%)｜赊销</span>-->
<!--                                        </div>-->

                                        <div class="item" v-for="(list, index) in skuType" :key="index">
                                            <ul class="tag">
                                                <li v-for="(item, i) in list.skuvalue" :key="i" :class="item === sku[index].skuValue ? 'on' : ''" @click="toChangeSku(index, item)">{{item}}</li>
                                            </ul>
                                        </div>

                                        <div class="item">
                                            <span class="text">{{stock}}</span>
                                        </div>

                                        <div class="item">
                                            <div class="number">
                                                <inputNumber size="mini" :precision="2" v-model="number" :min="0" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="right">
                            <div class="head">
                                <img src="../../../assets/img/shop_order_new.png" />
                                <h1>{{dataInfo.storeName}}</h1>
                            </div>

                            <div class="desc">
                                <div class="line">联系电话：<span :class="{on: !isLogin}">{{isLogin ? corporatePhone : '登录可见'}}</span></div>
                                <div class="line">证照信息：<img src="../../../assets/img/license.png" style="width: 21px; height: 22px; cursor: pointer" v-if="isLogin" @click="showLicense" /><span class="on" v-else>登录可见</span></div>
                                <div class="line ellipsis4 maxh80" style="align-items: flex-start">经营范围：<span style="display: contents;">{{legalBusinessScope}}</span></div>
                                <div class="storeBtn">
                                    <router-link :to="'/shop/store?id=' + storeId" class="store" target='_blank'>进店逛逛</router-link>
<!--                                    $router.push({path: '/shop/store', query: {id: storeId}})-->
                                    <el-button type="primary" class="keep" :loading="isSubmitLoading" @click="postStoreCollect">{{isStoreCollect ? '已收藏' : '收藏店铺'}}</el-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="btnGroup">
                        <a class="keep" href="javascript:void(0)" v-if="Number(isCollect) === 1" @click="putGoodsCollect">
                            <img src="../../../assets/img/keep_alive.png" class="icon" />已收藏
                        </a>
                        <a class="keep" href="javascript:void(0)" v-else @click="putGoodsCollect">
                            <img src="../../../assets/img/keep.png" class="icon" />收藏
                        </a>

                        <el-button type="primary" plain class="car" :loading="isSubmitLoading" @click="toSubmit(1)"><img src="../../../assets/img/shop_detail_addCar.png" />加入购物车</el-button>
                        <el-button type="primary" class="buy" :loading="isSubmitLoading" @click="toSubmit(2)">立即购买</el-button>
                    </div>
                </div>
            </div>

            <div class="goods_desc ql-snow">
                <div class="header">
                    <span class="title">商品详情</span>
                </div>

                <div class="info">
                    <span class="text" v-for="(item, index) in tag" :key="index"><span>{{item.name}}：</span>{{item.value}}</span>
                </div>

                <div class="desc ql-editor maxImageWidth" v-html="detail"></div>
            </div>
        </div>

        <imageViewer v-if="showViewer" :on-close="closeViewer" :url-list="showImageList" />
    </div>
</template>

<script>
  import {getGoodsInfo, getGoodsList, postCart, getStoreInfo, getGoodsCollect, putGoodsCollect} from '../../../api/shop/goods';
  import {imageURL} from "../../../utils/config";
  import {mapState} from "vuex";
  import {getShopStoreCollect, postShopStoreCollect} from "../../../api/shop/store";
  const inputNumber = () => import('../../../components/inputNumber');
  const imageViewer = () => import('../../../components/imageViewer');
  export default {
    name: "shopDetail",
    data () {
      return {
        isRequestLoading: false,
        category1Id: null,
        category1Value: '',
        category2Id: null,
        category2Value: '',
        category3Id: null,
        category3Value: '',
        name: '',
        price: '',
        number: 1,
        skuType: [],
        tag: [],
        detail: '',
        sku: [],
        imageSku: [],
        image: [],
        imageLarge: 0,
        imageURL: imageURL,
        isSubmitLoading: false,
        dataInfo: {},
        stock: null,
        listData: [],
        corporatePhone: '',
        legalBusinessScope: '',
        imageSlateX: 0,
        storeId: null,
        showViewer: false,
        showImageList: [],
        prevOverflow: '',
        isCollect: 2,  // 1-已收藏，2-未收藏
        isStoreCollect: false
      }
    },
    computed: {
      ...mapState({
        userStatus: state => state.user.userStatus,
        isLogin: state => state.user.isLogin,
      })
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getGoodsInfo();
      this.getList();
      if (this.isLogin) {
        this.getGoodsCollect()
      }
    },
    methods: {
      getList () {
        getGoodsList({
          goodsId: this.$route.query.id
        }).then(res => {
          if (res.code === 0) {
            this.listData = res.data.records
          }
        })
      },
      getGoodsCollect () {
        getGoodsCollect(this.$route.query.id).then(res => {
          if (res.code === 0) {
            this.isCollect = res.data
          }
        })
      },
      getGoodsInfo () {
        this.isRequestLoading = true
        getGoodsInfo({
          data: {
            id: this.$route.query.id
          }
        }).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.dataInfo = res.data
            this.category1Value = res.data.category1Value
            this.category2Value = res.data.category2Value
            this.category3Value = res.data.category3Value
            this.category1Id = res.data.category1Id
            this.category2Id = res.data.category2Id
            this.category3Id = res.data.category3Id
            this.name = res.data.name
            this.price = res.data.price
            this.skuType = res.data.skuType
            this.sku = res.data.sku
            this.tag = res.data.tag
            this.imageSku = res.data.imageSku
            this.image = res.data.image
            this.detail = res.data.detail
            this.stock = this.dataInfo.stock
            this.storeId = this.dataInfo.storeId
            this.getStoreInfo(this.dataInfo.storeId)
            if (this.isLogin) {
              this.getStoreCollect();
            }
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getStoreInfo (id) {
        getStoreInfo(id).then(res => {
          if (res.code === 0) {
            this.corporatePhone = res.data.corporatePhone;
            this.legalBusinessScope = res.data.legalBusinessScope;
            this.showImageList = [imageURL + res.data.businessLicenseImage]
          }
        })
      },
      getStoreCollect () {
        getShopStoreCollect(this.storeId).then(res => {
          if (res.code === 0) {
            this.isStoreCollect = res.data;
          }
        })
      },
      postStoreCollect () {
        postShopStoreCollect(this.storeId).then(res => {
          if (res.code === 0) {
            this.getStoreCollect();
          }
        })
      },
      toChangeSku (index, item) {
        this.sku[index].skuValue = item
        if (Number(index) === 0) {
          this.image = this.imageSku.filter(img => img.sku.skuValue === item)[0].imageValue;
          this.imageLarge = 0;
        }

        let changeSkuData = this.listData.filter(item => JSON.stringify(item.sku) === JSON.stringify(this.sku))[0];
        this.stock = changeSkuData.stock;
        this.price = changeSkuData.price;
        this.dataInfo = changeSkuData;
      },
      toSubmit (type) {
        if (!this.isLogin) {
          this.$router.push('/login')
          return false
        } else {
          if (Number(this.userStatus) === 6) {
            if (Number(this.number) === 0) {
              this.$message({
                message: '请输入商品数量',
                type: 'warning',
                showClose: true,
              });
              return false
            }

            if (Number(this.number) > Number(this.stock)) {
              this.$message({
                message: '商品库存不足',
                type: 'warning',
                showClose: true,
              });
              return false
            }

            if (type === 1) {
              this.postCart()
            } else {
              this.dataInfo.num = this.number
              this.dataInfo.goodsImageUrl = this.dataInfo.imageUrl
              this.dataInfo.goodsName = this.dataInfo.name
              this.dataInfo.goodsStock = this.dataInfo.stock
              this.dataInfo.goodsId = this.dataInfo.id
              let data = [{
                shoppingCartList: [this.dataInfo],
                store: {
                  storeName: this.dataInfo.storeName,
                  storeId: this.dataInfo.storeId
                }
              }]
              sessionStorage.setItem('submitOrderData', JSON.stringify(data))
              this.$router.push({path:'/shop/car/confirm', query: {type: '2'}})
            }
          } else {
            if (Number(this.userStatus) === 0) {
              this.$confirm('您还未提交实名认证，是否提交?', '提示', {
                confirmButtonText: '提交',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning'
              }).then(() => {
                this.$router.push('/shop/realName')
              }).catch(() => {});
            } else if (Number(this.userStatus) === 1) {
              this.$confirm('您还未提交企业认证，是否提交?', '提示', {
                confirmButtonText: '提交',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning'
              }).then(() => {
                this.$router.push('/admin/merchant/edit')
              }).catch(() => {});
            } else if (Number(this.userStatus) === 4 || Number(this.userStatus) === 7) {
              this.$confirm('您提交的企业认证未审核，是否查看?', '提示', {
                confirmButtonText: '查看',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning'
              }).then(() => {
                this.$router.push('/admin/merchant/edit')
              }).catch(() => {});
            } else if (Number(this.userStatus) === 5) {
              this.$confirm('您提交的企业认证审核失败，是否查看?', '提示', {
                confirmButtonText: '查看',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning'
              }).then(() => {
                this.$router.push('/admin/merchant/edit')
              }).catch(() => {});
            }
          }
        }
      },
      postCart () {
        postCart({
          data: {
            goodsId: this.dataInfo.id,
            storeId: this.dataInfo.storeId,
            num: this.number,
            channel: 1
          }
        }).then(res => {
          this.isSubmitLoading = false
          if (res.code === 0) {
            this.$confirm('添加购物车成功，是否前往购物车查看?', '提示', {
              confirmButtonText: '查看购物车',
              cancelButtonText: '继续浏览',
              closeOnClickModal: false,
              type: 'success'
            }).then(() => {
              this.$router.push('/shop/car')
            }).catch(() => {});
          }
        }).catch(() => {
          this.isSubmitLoading = false
        })
      },
      goList (id) {
        const { href } = this.$router.resolve({path: '/shop/list', query: {type: '2', id: id, page: '1'}});
        window.location.href = href;
      },
      imageLargePre () {
        if (this.image.length > 4) {
          if (Number(this.imageLarge) === 0) {
            this.imageLarge = this.image.length - 1
            this.imageSlateX = -62
          } else if (Number(this.imageLarge) === 1) {
            this.imageLarge = this.imageLarge - 1
            if (this.imageSlateX === -62) {
              this.imageSlateX = 0
            }
          } else {
            this.imageLarge = this.imageLarge - 1
          }
        } else {
          this.imageSlateX = 0

          if (Number(this.imageLarge) === 0) {
            this.imageLarge = this.image.length - 1
          } else {
            this.imageLarge = this.imageLarge - 1
          }
        }
      },
      imageLargePlus () {
        if (this.image.length > 4) {
          if (Number(this.imageLarge) === 4) {
            this.imageLarge = 0
            this.imageSlateX = 0
          } else if (Number(this.imageLarge) === 3) {
            this.imageLarge = this.imageLarge + 1
            if (this.imageSlateX === 0) {
              this.imageSlateX = -62
            }
          } else {
            this.imageLarge = this.imageLarge + 1
          }
        } else {
          this.imageSlateX = 0

          if (Number(this.imageLarge) === (this.image.length - 1)) {
            this.imageLarge = 0
          } else {
            this.imageLarge = this.imageLarge + 1
          }
        }
      },
      showLicense () {
        this.prevOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
        this.showViewer = true
      },
      closeViewer () {
        document.body.style.overflow = this.prevOverflow;
        this.showViewer = false
      },
      putGoodsCollect () {
        putGoodsCollect(this.$route.query.id).then(res => {
          if (res.code === 0) {
            this.getGoodsCollect()
          }
        })
      }
    },
    components: {
      inputNumber, imageViewer
    }
  }
</script>
